"use client";

import { assets } from "@koble/assets/assets";
import publicRouteManager from "@koble/config/src/publicRouteManager";
import ssoRouteManager from "@koble/config/src/ssoRouteManager";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { LikeButton } from "@koble/ui/src/CommonButton";
import CommonJobCard from "@koble/ui/src/CommonJob/CommonJobCard/CommonJobCard";
import { Card, Col, Row, Space } from "antd";

import {
  PublicSwitchFooter,
  PublicSwitchLogoCloud,
  PublicSwitchPreFooter,
  PublicSwitchSectionTitle,
  PublicSwitchTestimony,
  PublicSwitchTextAndImageSection,
  PublicSwitchTextCarousel,
} from "@/app/public-switch/common";
import PublicSwitchPrimaryButton from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchPrimaryButton";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import PublicSwitchUserStudentOfferCol from "@/app/public-switch/user-student/PublicSwitchUserStudentOfferCol";
import AnimateOnScroll from "@/common/AnimateOnScroll";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import { PUBLIC_GRAY_GRADIENT_FROM_COLOR } from "@/common/publicColors";
import es from "@/lang/es";

const Spacing = ({ mobile, desktop }: { mobile: number; desktop: number }) => {
  const { isMobile } = useIsMobile();

  return <div style={{ height: isMobile ? mobile : desktop }} />;
};

const PublicSwitchUserStudentPage = () => {
  const { isMobile } = useIsMobile();
  const { jobs } = usePublicSwitchPage();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Spacing mobile={160} desktop={200} />

      <PublicSwitchLogoCloud />

      <Spacing mobile={60} desktop={100} />

      <AnimateOnScroll style={{ textAlign: "center" }}>
        <PublicSwitchSectionTitle
          title={es.switch.userStudent.firstSection.title}
          subTitle={es.switch.userStudent.firstSection.subTitle}
        />
      </AnimateOnScroll>

      <Spacing mobile={50} desktop={40} />

      <Space
        size={isMobile ? 50 : 40}
        direction="vertical"
        style={{
          maxWidth: 1100,
          padding: "0 16px",
        }}
      >
        <PublicSwitchTextAndImageSection
          title={es.switch.userStudent.firstSection["1"].title}
          subTitle={es.switch.userStudent.firstSection["1"].subTitle}
          description={es.switch.userStudent.firstSection["1"].description}
          side="left"
          imgSrc={assets.PUBLIC_SEARCH_WORK_STEP_1}
          imgAlt={es.switch.userStudent.firstSection["1"].imgAlt}
        />

        <PublicSwitchTextAndImageSection
          title={es.switch.userStudent.firstSection["2"].title}
          subTitle={es.switch.userStudent.firstSection["2"].subTitle}
          description={es.switch.userStudent.firstSection["2"].description}
          side="right"
          imgSrc={assets.PUBLIC_SEARCH_WORK_STEP_2}
          imgAlt={es.switch.userStudent.firstSection["2"].imgAlt}
        />

        <PublicSwitchTextAndImageSection
          title={es.switch.userStudent.firstSection["3"].title}
          subTitle={es.switch.userStudent.firstSection["3"].subTitle}
          description={es.switch.userStudent.firstSection["3"].description}
          side="left"
          imgSrc={assets.PUBLIC_SEARCH_WORK_STEP_3}
          imgAlt={es.switch.userStudent.firstSection["3"].imgAlt}
        />
      </Space>

      <Spacing mobile={35} desktop={25} />

      <AnimateOnScroll
        direction="bottomToTop"
        delay={600}
        threshold={0.2}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a href={ssoRouteManager.signUpUserStudent(true)}>
          <PublicSwitchPrimaryButton
            label={es.switch.userStudent.secondSection.button}
            color="white"
            background={PUBLIC_GRAY_GRADIENT_FROM_COLOR}
          />
        </a>
      </AnimateOnScroll>

      <Spacing mobile={100} desktop={140} />

      <PublicSwitchTextCarousel />

      <Spacing mobile={100} desktop={100} />

      <AnimateOnScroll style={{ textAlign: "center", padding: "0 16px" }}>
        <PublicSwitchSectionTitle
          title={es.switch.userStudent.secondSection.title}
          subTitle={es.switch.userStudent.secondSection.subTitle}
        />
      </AnimateOnScroll>

      <Spacing mobile={40} desktop={20} />

      <div
        style={{
          maxWidth: 1100,
          padding: "0 16px",
        }}
      >
        <Row gutter={[16, 40]} justify="center">
          <PublicSwitchUserStudentOfferCol
            background={generatePublicLinearGradient({
              colorType: "blue",
              angle: 90,
            })}
            title={es.switch.userStudent.secondSection["1"].title}
            subTitle={es.switch.userStudent.secondSection["1"].subTitle}
            src={assets.PUBLIC_KOBLE_BENEFITS_1}
            imageHeight={200}
          />
          <PublicSwitchUserStudentOfferCol
            delay={150}
            background={generatePublicLinearGradient({
              colorType: "orange",
              angle: 90,
            })}
            title={es.switch.userStudent.secondSection["2"].title}
            subTitle={es.switch.userStudent.secondSection["2"].subTitle}
            src={assets.PUBLIC_KOBLE_BENEFITS_2}
            imageHeight={200}
          />
          <PublicSwitchUserStudentOfferCol
            delay={300}
            background={generatePublicLinearGradient({
              colorType: "gray",
              angle: 180,
            })}
            title={es.switch.userStudent.secondSection["3"].title}
            subTitle={es.switch.userStudent.secondSection["3"].subTitle}
            src={assets.PUBLIC_KOBLE_BENEFITS_3}
            imageHeight={200}
          />
          <PublicSwitchUserStudentOfferCol
            delay={450}
            background={generatePublicLinearGradient({
              colorType: "orange",
              angle: 90,
            })}
            title={es.switch.userStudent.secondSection["4"].title}
            subTitle={es.switch.userStudent.secondSection["4"].subTitle}
            src={assets.PUBLIC_KOBLE_BENEFITS_4}
            imageHeight={200}
          />
        </Row>

        <Spacing mobile={35} desktop={25} />

        <AnimateOnScroll
          direction="bottomToTop"
          delay={600}
          threshold={0.2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a href={ssoRouteManager.signUpUserStudent(true)}>
            <PublicSwitchPrimaryButton
              label={es.switch.userStudent.secondSection.button}
              color="white"
              background={PUBLIC_GRAY_GRADIENT_FROM_COLOR}
            />
          </a>
        </AnimateOnScroll>
      </div>
      <Spacing mobile={100} desktop={120} />

      {isMobile ? (
        <div
          style={{
            padding: "0 16px",
            textAlign: "center",
          }}
        >
          <PublicSwitchSectionTitle
            title={es.switch.userStudent.jobs.title}
            subTitle={es.switch.userStudent.jobs.subTitle}
          />
        </div>
      ) : (
        <div
          style={{
            maxWidth: 1100,
            padding: "0 16px",
            textAlign: "left",
            width: "100%",
          }}
        >
          <PublicSwitchSectionTitle
            title={es.switch.userStudent.jobs.title}
            subTitle={es.switch.userStudent.jobs.subTitle}
            subTitleFontSize={38}
            subTitleLineHeight="40px"
          />
        </div>
      )}

      <Spacing mobile={20} desktop={20} />

      <div
        style={{
          width: "100%",
          maxWidth: 1100,
          padding: "0 16px",
        }}
      >
        <Card
          style={{
            backgroundColor: "#F3F4F6",
          }}
          styles={{
            body: {
              padding: isMobile ? 16 : 60,
            },
          }}
        >
          <Row gutter={[40, 40]}>
            {jobs.slice(0, 6).map((job) => (
              <Col key={job.jobId} xs={24} md={12} lg={8}>
                <CommonJobCard
                  job={job}
                  ssrUrl={publicRouteManager.job(job.jobId)}
                  profileImagePlaceholder={assets.PLACEHOLDER_SQUARE}
                  extraContent={
                    <a
                      href={ssoRouteManager.signUpUserStudent(true)}
                      title="Inicia sesión para dar like"
                      style={{ width: "100%" }}
                    >
                      <LikeButton likeButtonStatus="notLiked" filled />
                    </a>
                  }
                />
              </Col>
            ))}
          </Row>{" "}
        </Card>
      </div>

      <Spacing mobile={120} desktop={120} />

      <AnimateOnScroll
        threshold={0.2}
        style={{
          maxWidth: 700,
          textAlign: "center",
          padding: "0 16px",
        }}
      >
        <PublicSwitchSectionTitle
          title={es.switch.userStudent.testimony.title}
          subTitle={es.switch.userStudent.testimony.subTitle}
          subTitleFontSize={24}
          subTitleLineHeight="30px"
        />
      </AnimateOnScroll>

      <Spacing mobile={30} desktop={20} />

      <AnimateOnScroll
        threshold={0.2}
        style={{
          maxWidth: 1100,
          padding: "0 16px",
        }}
      >
        <PublicSwitchTestimony
          name={es.switch.userStudent.testimony.name}
          position={es.switch.userStudent.testimony.position}
          text={es.switch.userStudent.testimony.text}
        />
      </AnimateOnScroll>

      <Spacing mobile={120} desktop={120} />

      <div
        style={{
          maxWidth: 1100,
          padding: "0 16px",
        }}
      >
        <PublicSwitchTextAndImageSection
          title={es.switch.userStudent.fourthSection.title}
          subTitle={es.switch.userStudent.fourthSection.subTitle}
          description={es.switch.userStudent.fourthSection.description}
          side="left"
          imgSrc={assets.PUBLIC_ORANGE_GRADIENT}
          imgAlt={es.switch.userStudent.firstSection["3"].imgAlt}
          imageOnBottom
        />
      </div>

      <Spacing mobile={60} desktop={60} />

      <PublicSwitchPreFooter />

      <PublicSwitchFooter />
    </div>
  );
};

export default PublicSwitchUserStudentPage;
