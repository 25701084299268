import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Col, Space, Typography } from "antd";

import AnimateOnScroll from "@/common/AnimateOnScroll";

const PublicSwitchUserStudentOfferCol = ({
  background,
  title,
  subTitle,
  src,
  imageHeight,
  delay,
}: {
  background: string;
  title: string;
  subTitle: string;
  src?: string;
  imageHeight?: number;
  delay?: number;
}) => {
  const { isMobile } = useIsMobile();

  const content = (
    <div
      style={{
        height: 400,
        background,
        borderRadius: 8,
        padding: "16px 24px",
      }}
    >
      <Space direction="vertical">
        <Typography.Text
          style={{
            color: "white",
            fontWeight: 600,
            fontSize: 18,
          }}
        >
          {title}
        </Typography.Text>
        <Typography.Text
          style={{
            color: "white",
            fontSize: 14,
            fontWeight: 400,
            marginTop: 10,
          }}
        >
          {subTitle}
        </Typography.Text>
      </Space>
      {src && (
        <img
          src={src}
          alt="ventajas"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: imageHeight ?? 200,
          }}
        />
      )}
    </div>
  );

  return (
    <Col xs={isMobile ? 24 : 6}>
      <AnimateOnScroll
        direction="bottomToTop"
        delay={delay ?? 0}
        // reappear={!isMobile}
        threshold={0.2}
      >
        {content}
      </AnimateOnScroll>
    </Col>
  );
};

export default PublicSwitchUserStudentOfferCol;
